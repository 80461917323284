import React, { useEffect, useState } from 'react'

import axios from 'axios'
import getConfig from '../utils/get-config'
import LazyLoad from 'react-lazyload'
import Lightbox from 'react-image-lightbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import 'react-image-lightbox/style.css'

const PhotosGallery = () => {
    const [gallery, setGallery] = useState([]);
    const [isPhotoOpen, setPhotoOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [photoMaxIndex, setPhotoMaxIndex] = useState(0);

    const clickOnPhoto = (index) => {
        setPhotoIndex(index)
        setPhotoOpen(true)
        document.getElementById('main').classList.add('modal-open')
    }

    const clickOnPhotoModalOverlay = () => {
        setPhotoOpen(false)
        document.getElementById('main').classList.remove('modal-open')
    }

    useEffect(() => {
        axios.get(`${getConfig('webServciesUrl')}get-photos.php`).then(response => {
            setGallery(response.data)
            setPhotoMaxIndex(response.data.length-1)
        }).catch(function (error) {
            console.log(error)
        })
    }, [])
    
    return (
        <>
            <section id="photos-gallery">
                {isPhotoOpen && (
                    <>
                        <div class="photo-modal-overlay" onClick={e => clickOnPhotoModalOverlay()}></div>
                        <div class="photo-modal show" data-index={photoIndex}>
                            <img alt="Photo" src={gallery[photoIndex]} />
                            <button class="previous-button" onClick={e => setPhotoIndex(photoIndex-1 < 0 ? photoMaxIndex : photoIndex-1)}>
                                <FontAwesomeIcon className="icon" icon={faChevronLeft} alt="Photo précédente" />
                            </button>
                            <button class="next-button" onClick={e => setPhotoIndex(photoIndex+1 > photoMaxIndex ? 0 : photoIndex+1)}>
                                <FontAwesomeIcon className="icon" icon={faChevronRight} alt="Photo suivante" />
                            </button>
                        </div>
                    </>
                )}
                <div className="gallery">
                    {gallery.map((photo, index) => {
                        return(
                            <LazyLoad className="photo" key={index}>
                                <img alt="Photo" src={photo} height={300} width={200} onClick={e => clickOnPhoto(index)} />
                            </LazyLoad>
                        )
                    })}
                </div>
            </section>
        </>
    )
}

export default PhotosGallery