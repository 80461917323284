import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import PhotosGallery from '../components/photos-gallery'

const Photos = () => {
    return (
        <>
            <Helmet>
                <title>Educa'dog : Centre d'éducation positive à Léglise - Photos</title>
                <descrtiption>Educa'dog est un centre d'éducation canine positive situé chemin du Stria à Léglise, en province de Luxembourg. Nous n'utilisons que les méthodes positive et respectueuses du chien.</descrtiption>
            </Helmet>
            <Layout>
                <PhotosGallery></PhotosGallery>
            </Layout>
        </>
    )
}

export default Photos